import * as styles from './Adaptive.module.scss';
import cn from 'classnames';

export const Adaptive = ({ breakpoint = 'lg', over, under }: { breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl', over?: React.ReactNode, under?: React.ReactNode }) => {
    return <div className={cn(styles.adaptive, styles[breakpoint])}>
        <div className={styles.over}>
            {over}
        </div>
        <div className={styles.under}>
            {under}
        </div>
    </div>
}